import React, { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import SignupForm from "./SignupForm";

const Newsletter = () => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setStatus("");
    setMessage("");
  };

  const onStatusChange = (newStatus, newMessage) => {
    setStatus(newStatus);
    setMessage(newMessage);
  };

  const handleDownloadGuide = () => {
    window.location.href = "https://lesedi-agency.aflip.in/strategies";
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>
              Elevate Your Online Visibility
              <br />
            </h3>
            <h5>
              Explore essential strategies that will help your business thrive
              online in the digital world.
            </h5>
            {status === "sending" && <Alert>Sending...</Alert>}
            {status === "error" && <Alert variant="danger">{message}</Alert>}
            {status === "success" && <Alert variant="success">{message}</Alert>}
          </Col>
          <Col md={6} xl={7}>
            {/*<SignupForm onStatusChange={onStatusChange} /><input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" /><button type="submit" disabled>Submit</button>*/}
            <div className="new-email-bx">
              <button
                type="button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleDownloadGuide}
              >
                Get Your Free Guide
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default Newsletter;
