import { Container, Row, Col } from "react-bootstrap";
import MailchimpForm from "./MailChimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {<MailchimpForm />}
          <Col size={12} sm={6}>
            <img src="..\Logobrand.png" alt="Lesedi Agency Innovations" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <h5 style={{ color: "#ffb6c1" }}>
              <a href="https://wa.me/+27605299925">Contact us on WhatsApp 📲</a>
            </h5>
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/lesedi-queen-masina-95b543b8/">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://www.facebook.com/iamqueenmasina">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="https://www.instagram.com/iamqueenlesedi/">
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>

            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
