import { Helmet } from "react-helmet";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Lesedi Agency Innovations</title>
        <meta
          name="description"
          content="Lesedi Agency Innovations offers innovative AI solutions, custom software development, and digital agency services to help businesses thrive in the digital age."
        />
        <meta
          name="keywords"
          content="Lesedi Agency Innovations, Digital Marketing, software development, Website Creation, social media marketing, technology, content creation"
        />
        {/* Add other metadata here */}
      </Helmet>
      {/* Your app content */}
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
